import {FlexPage} from '@hconnect/common/components/FlexPage'
import {Grid} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {WorkOrdersSummaryContainer} from '../containers/WorkOrdersSummaryContainer'

type PathParameter = {
  plantId?: string
}

export const ReliabilityEngineerDashboard = () => {
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('plantId is missing')

  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  return (
    <FlexPage title={t(`${hrocPrefix}.reliabilityEngineerDashboard.pageTitle`)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container direction={'column'} spacing={2}>
            <Grid item>
              <WorkOrdersSummaryContainer plantId={plantId} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container direction={'column'} spacing={2} />
        </Grid>
      </Grid>
    </FlexPage>
  )
}
