import {mergeSx} from '@hconnect/uikit/src/lib2'
import {CircularProgress, SxProps, Theme, Typography} from '@mui/material'
import {isArray, isEmpty} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ErrorPlaceholder} from '../../shared/components/ErrorPlaceholder'
import {PlaceholderWrapper} from '../../shared/components/PlaceholderWrapper'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'

type DataContentWrapperProps<T> = {
  children: React.ReactNode
  isLoading: boolean
  isError: boolean
  isRefetching?: boolean
  data?: T
  isNoData?: (data?: T) => boolean
  retryFunction?: () => void
  progressSx?: SxProps<Theme>
}

// TODO: Will reause this component in performanceDashboard with refactor task HCP-76971
export function DataContentWrapper<T>({
  isLoading,
  children,
  isError,
  isRefetching,
  data,
  isNoData = (data) => !data || (isArray(data) && isEmpty(data)),
  retryFunction,
  progressSx
}: DataContentWrapperProps<T>) {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  if (isLoading) {
    return (
      <PlaceholderWrapper noBackground>
        <CircularProgress sx={mergeSx({color: 'common.white'}, progressSx)} />
      </PlaceholderWrapper>
    )
  }

  if (isError) {
    return (
      <PlaceholderWrapper>
        <ErrorPlaceholder
          retryFunction={retryFunction}
          isRefetching={isRefetching}
          translationPrefix={hrocPrefix}
        />
      </PlaceholderWrapper>
    )
  }

  if (isNoData(data)) {
    return (
      <PlaceholderWrapper>
        <Typography>{t(`${hrocPrefix}.label.noData`)}</Typography>
      </PlaceholderWrapper>
    )
  }

  return <>{children}</>
}
